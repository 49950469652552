import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Badge } from 'reactstrap';
import DeleteConfirmationModal from 'components/basic/modals/DeleteConfirmationModal';
import CloseConfirmationModal from 'components/basic/modals/CloseConfirmationModal';
import * as Clients from 'client/ApiClient';
import { CircularProgress } from '@mui/material';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { enqueueNotification } from "../../store/Notifications"
import { store } from "AppContext"
import * as SourceSystem from 'constants/SourceSystem';


type ConversionAdministrationPopupProps = {
    conversionId?: string,
    isOpen: boolean,
    onClose: (ev: ConversionAdministrationPopupOnCloseEvent) => void
    currentUser: Clients.IUserLoginDetailsModel | undefined;
    conversionType?: string,
}

export type ConversionAdministrationPopupOnCloseEvent = {
    refresh: boolean,
}

type ConversionAdministrationPopupState = {
    conversion?: Clients.ConversionModel,
    deleteConfirmationModal: boolean,
    releaseConfirmationModal: boolean,
    loading: boolean,
    hubConnection: HubConnection | null;
    status: any;
    timeout: any;
    stage: boolean;
    refresh: boolean;
    buttonLock: boolean;
    options: { [key: string]: Clients.ConversionOptionValueModel } | undefined;
    refreshCall: string;
}

export class ConversionAdministrationPopup extends React.Component<
    ConversionAdministrationPopupProps,
    ConversionAdministrationPopupState
>{
    private readonly conversionClient: Clients.ConversionClient = new Clients.ConversionClient();
    private readonly options: Clients.ConversionOptionsClient = new Clients.ConversionOptionsClient();
    private readonly conversonGenericClient: Clients.GenericClient = new Clients.GenericClient();
    private readonly executions: Clients.ExecutionsClient = new Clients.ExecutionsClient();

    constructor(props: Readonly<ConversionAdministrationPopupProps>) {
        super(props);
        this.state = {
            conversion: undefined,
            deleteConfirmationModal: false,
            releaseConfirmationModal: false,
            loading: false,
            hubConnection: null,
            status: null,
            timeout: null,
            stage: false,
            refresh: false,
            buttonLock: false,
            options: undefined,
            refreshCall: '',
        }
    }

    componentDidMount() {
        this.refreshCall();
    }

    refreshCall() {
        clearInterval(this.state.timeout);
        const link = '/executions';
        const refreshCall = this.props.conversionType && this.props.conversionType!.toUpperCase() === SourceSystem.epicToAMS360Id ? "00-00-00-00-Tier-Prep.dtsx" : "Orchestration-Map.dtsx"
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        this.setState({ hubConnection, refreshCall }, () => {
            this.state.hubConnection!
                .start()
                .then(() => console.log("Connection started for Administration"))
                .catch(() => console.log("there is an error"));

            this.state.hubConnection!.on("Administration", (execution: Clients.ExecutionsModel) => {
                clearInterval(this.state.timeout);
                this.buttonLock();
                if ((execution.description === "Succeeded" || execution.description === "Completed") && execution.userId === this.props.currentUser!.id) {
                    store.dispatch(enqueueNotification({ type: 'success', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                }
                else if ((execution.description === "Failed" || execution.description === "Canceled" || execution.description === "Ended Unexpectedly" || execution.description === "Stopping") && execution.userId === this.props.currentUser!.id) {
                    store.dispatch(enqueueNotification({ type: 'error', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                }
                if (execution.conversionId === this.props.conversionId && execution.packageName === this.state.refreshCall) {
                    if (execution.endTime) {
                        clearInterval(this.state.timeout);
                        this.setState({
                            timeout: null, status: null
                        }, () => this.setStatusForRefreshSourceAndTargetData(execution!.estimatedFinish, execution!.description, execution!.endTime))
                    } else if (execution.estimatedFinish) {
                        this.setState({ timeout: setInterval(() => { this.setStatusForRefreshSourceAndTargetData(execution!.estimatedFinish, execution!.description, execution!.endTime) }, 1000) })
                    } else if (execution.status === 2 && execution.startTime === null) {
                        clearInterval(this.state.timeout);
                        this.setStatusForRefreshSourceAndTargetData(execution!.estimatedFinish, execution!.description, execution!.endTime)
                    } else {
                        clearInterval(this.state.timeout);
                    }
                }
            });
        })
    }

    componentWillUnmount() {
        this.state.hubConnection!.stop();
        clearInterval(this.state.timeout);
    }

    private setStatusForRefreshSourceAndTargetData(timeEstimate: Date | undefined, status: string | undefined, endTime: Date | undefined) {
        let color = ""
        if (endTime) {
            clearInterval(this.state.timeout);
            const dt = new Date(endTime);
            const day = dt.getDate(),
                month = dt.getMonth(),
                year = dt.getFullYear(),
                minutes = (dt.getMinutes() < 10 ? '0' : "") + dt.getMinutes();
            let hours = dt.getHours();
            const period = (hours < 12) ? 'AM' : 'PM';
            if (period === 'PM' && hours !== 12) hours = hours - 12;
            if (hours === 0) hours = 12;
            if (status === "Canceled" || status === "Failed" || status === "Ended Unexpectedly") {
                color = "danger"
                this.setState({
                    loading: false,
                    status: < h6 className='status' > <Badge color={color}> Migrate completed on {
                        `${(month + 1)}/${day}/${year} ${hours}:${minutes} ${period}`
                    } - Status: {status} </Badge></h6>
                });
            } else if (status === "Succeeded" || status === "Completed") {
                color = "success"
                this.setState({
                    loading: false,
                    status: < h6 className='status' > <Badge color={color}> Migrate completed on {
                        `${(month + 1)}/${day}/${year} ${hours}:${minutes} ${period}`
                    } - Status: {status} </Badge></h6>
                });
            }
        } else {
            color = "primary"
            const now = new Date().valueOf();
            const estimate = timeEstimate ? new Date(timeEstimate).valueOf() : now;
            const timeRemain: number = (estimate - now) / 60000;
            const timeLeft = timeEstimate && timeRemain > 0 ? `${timeRemain.toFixed(2)} minutes` : "Pending";
            this.setState({
                loading: true,
                status: < h6 className='status' > <Badge color={color}>Estimate: {timeLeft} - Status: {status} </Badge></h6>
            });
        }
    }

    componentDidUpdate(prevProps: ConversionAdministrationPopupProps) {
        if (prevProps.isOpen === false && this.props.isOpen === true) {
            clearInterval(this.state.timeout);
            this.setState({ refresh: false })
            this.updateConversion(this.props.conversionId);
            this.buttonLock();
            this.refreshCall();
            if (this.props.conversionId) {
                this.options.get(this.props.conversionId)
                    .then(values => {
                        const options = values!.reduce(
                            (result, current) => ({ ...result, [current.id!]: current, }), {});
                        this.setState({ options });
                    })
                    .catch(error => { });
            }
        }
    }

    private updateConversion = (conversionId?: string) => {
        if (conversionId) {
            this.conversionClient.get(conversionId)
                .then(response =>
                    this.setState({ conversion: response! }, () => {
                        this.checkConversion();
                    }))
                .catch((e) => { console.log(e) });
            this.executions!.listExecutions(conversionId)
                .then(results => {
                    if (results!.length === 0) {
                        this.setState({
                            loading: false,
                            status: null,
                            refresh: true,
                        })
                    }
                    else {
                        const tierPrep = results!.filter(r => r.packageName === this.state.refreshCall)
                        let lastExecution = tierPrep![tierPrep!.length - 1];
                        const estimatedFinish = lastExecution.estimatedFinish;
                        if (lastExecution.endTime) clearInterval(this.state.timeout);
                        const endTime = lastExecution.endTime;
                        const clientsRan = results!.filter(r => r.packageName === '02-11-00-02-Client-Extract-Load.dtsx' || r.packageName === 'Orchestration-StageValidateAndLoad.dtsx' || r.packageName === 'Orchestration-LoadData.dtsx')
                        const refresh = clientsRan.length > 0 ? false : true;
                        this.setState({
                            refresh, timeout: setInterval(() => { this.setStatusForRefreshSourceAndTargetData(estimatedFinish, lastExecution.description!, endTime) }, 1000)
                        });
                    }
                })
                .catch(e => { console.log(e) })

        } else {
            this.setState({ conversion: undefined });
        }
    }

    private refreshSourceTargetData = () => {
        this.setState({
            loading: true,
            status: null,
            buttonLock: true,
        }, () => {
            const conversion = this.state.conversion;
            const conversionType = conversion!.conversionTypeId!.toUpperCase() === SourceSystem.ams360ToAMS360Id ? 'merge' : 'epic'
            const user = this.props.currentUser;
            if (conversion && user) {
                this.conversonGenericClient.runConversionRefreshAction(conversion.id!, user.id!, conversionType)
                    .then(() => { })
                    .catch(() => { });
            }
        });
    }

    private deleteConversion = () => {
        const conversion = this.state.conversion;
        this.removeDeleteConfirmationModal();
        if (conversion) {
            this.conversionClient.delete(conversion.id!)
                .then(() => {
                    this.props.onClose({
                        refresh: true,
                    });
                })
                .catch(() => { });
        }
    }

    openDeleteConfirmationModal = () => {
        this.setState({ deleteConfirmationModal: true })
    }

    removeDeleteConfirmationModal = () => {
        this.setState({ deleteConfirmationModal: false });
    }

    private releaseConversion = () => {
        const conversion = this.state.conversion;
        this.removeReleaseConfirmationModal();

        if (conversion) {
            this.conversionClient.release(conversion.id!)
                .then(() => {
                    this.props.onClose({
                        refresh: true,
                    });
                })
                .catch(() => { });
        }
    }

    openReleaseConfirmationModal = () => {
        this.setState({ releaseConfirmationModal: true })
    }

    removeReleaseConfirmationModal = () => {
        this.setState({ releaseConfirmationModal: false });
    }

    checkConversion = () => {
        const conversion = this.state.conversion;
        if (conversion) {
            const conversionType = conversion.conversionTypeId!.toUpperCase() === SourceSystem.ams360ToAMS360Id ? 'merge' : 'epic'
            this.conversionClient.getCheckInternalConnection(conversion!.staging || null, 'staging', conversionType)
                .then((result: boolean) => {
                    if (result) {
                        const stage = result
                        this.setState({ stage });
                    } else {
                        this.checkConversion()
                    }
                })
                .catch((err: any) => {
                    console.log(err)
                })
        }
    }

    buttonLock = () => {
        const conversionId = this.props.conversionId ? this.props.conversionId : '';
        if (conversionId !== '') {
            this.executions.listExecutionsAllTierBoolean(conversionId)
                .then((response) => {
                    if (response) {
                        this.setState({ buttonLock: response.isLocked })
                    }
                })
                .catch(error => { })
        }
    }

    render() {
        const conversion = this.state.conversion ? this.state.conversion : undefined
        return (
            <Modal isOpen={this.props.isOpen} size="lg">
                <ModalHeader toggle={() => this.props.onClose({
                    refresh: this.state.refresh && !this.state.conversion!.dateCompleted,
                })}>
                    Conversion: {this.state.conversion && this.state.conversion.description}
                </ModalHeader>
                <ModalBody className='no-border'>
                    <ListGroup flush>
                        {conversion !== undefined && this.state.stage && this.state.refresh && this.state.options && Object.keys(this.state.options).length > 0 && (this.state.conversion!.isCertified || this.state.conversion!.epicVersion === null) ?
                            <ListGroupItem tag="button" action onClick={() => this.refreshSourceTargetData()} disabled={this.state.loading || this.state.buttonLock}>
                                <div className="custom-items">
                                    <ListGroupItemHeading>Refresh source and target data</ListGroupItemHeading>
                                    <CircularProgress color="inherit" className={this.state.loading ? "" : "custom-loading-icon"} size={20} />
                                    {this.state.status}
                                </div>
                                <ListGroupItemText>Retrieve data from the currently identified connection string to both the source and target databases for all tiers.</ListGroupItemText>
                            </ListGroupItem> :
                            null
                        }
                        {this.state.options && Object.keys(this.state.options).length === 0 &&
                            <ListGroupItem tag="button" action disabled={true}>
                                <div className="custom-items">
                                    <ListGroupItemHeading>Refresh source and target data</ListGroupItemHeading>
                                </div>
                                <ListGroupItemText>Please submit conversion options to access refresh.</ListGroupItemText>
                            </ListGroupItem>
                        }
                        {this.state.conversion && !this.state.conversion!.dateCompleted && <ListGroupItem tag="button" action onClick={this.openReleaseConfirmationModal} disabled={this.state.buttonLock}>
                            <ListGroupItemHeading>Confirm conversion completed</ListGroupItemHeading>
                            <ListGroupItemText>Conversion is now completed and target database will be released</ListGroupItemText>
                        </ListGroupItem>}
                        <CloseConfirmationModal
                            isOpen={this.state.releaseConfirmationModal}
                            title={'Confirm Conversion Completed: ' + (this.state.conversion && this.state.conversion.description)}
                            message={'Please confirm to release ' + (this.state.conversion && this.state.conversion.description)}
                            onSubmit={this.releaseConversion}
                            onClose={this.removeReleaseConfirmationModal}
                        />
                        {this.state.conversion &&
                            <ListGroupItem tag="button" action onClick={this.openDeleteConfirmationModal} disabled={this.state.buttonLock}>
                                <ListGroupItemHeading>Delete conversion</ListGroupItemHeading>
                                <ListGroupItemText>Delete conversion. This operation cannot be reverted.</ListGroupItemText>
                            </ListGroupItem>}
                    </ListGroup>
                    {conversion !== undefined ?
                        <DeleteConfirmationModal
                            isOpen={this.state.deleteConfirmationModal}
                            title={'Delete Conversion: ' + (this.state.conversion && this.state.conversion.description)}
                            message={'Please confirm to delete ' + (this.state.conversion && this.state.conversion.description)}
                            onSubmit={this.deleteConversion}
                            onClose={this.removeDeleteConfirmationModal}
                        /> : null}
                </ModalBody>
                <ModalFooter>
                    <Button
                        className='submit-button-orange'
                        onClick={() => this.props.onClose({
                            refresh: false,
                        })}
                    >Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

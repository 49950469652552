import classnames from 'classnames';
import ConversionReportsPage from 'components/conversion/reports/List';
import * as React from 'react';
import { Route, RouteComponentProps, withRouter, useLocation } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ConversionEditPage from './Edit';
import AdditionalDataPage from './AdditionalData';
import MergeStageTablePage from './MergeStageTable';
import MergeToolPage from './MergeTool';
import OptionsPage from './mapping/Options';
import MiniDashPage from './MiniDashboard';
import { ApplicationState } from 'store';
import { connect } from 'react-redux';
import { SecureContainer } from 'components/basic/Authentication';
import * as Clients from 'client/ApiClient';
import * as Roles from 'constants/Roles';
import * as SourceSystem from 'constants/SourceSystem';
import * as MiniConstants from 'constants/MiniConversion';
import { actionCreators } from "../../store/Select"
import { store } from "AppContext"

interface TabsPageArgs {
    conversionId: string,
    tabId: string,
}

type TabsProps = RouteComponentProps<TabsPageArgs> & {
    currentUser: Clients.IUserLoginDetailsModel | undefined,
    currentTab: TabName,
};

interface TabsState {
    reload: boolean;
    currentConversion: Clients.ConversionModel | undefined;
    options: { [key: string]: Clients.ConversionOptionValueModel } | undefined;
    stage: boolean;
}

type TabName = 'edit' | 'options' | 'additionaldata' | 'reports' | 'mergestagetable' | 'mergetool' | 'minidash' | '';

class ConversionTabsPage extends React.Component<TabsProps, TabsState, TabsPageArgs>{
    private options: Clients.ConversionOptionsClient = new Clients.ConversionOptionsClient();
    private conversionId: string = '';
    private customersFile: boolean = false;
    private policiesFile: boolean = false;
    private conversion: Clients.ConversionClient = new Clients.ConversionClient();

    constructor(props: TabsProps) {
        super(props)
        this.conversionId = props.match.params.conversionId
        const { state }: any = this.props.location
        this.state = {
            reload: false,
            currentConversion: undefined,
            options: undefined,
            stage: false,
        }
    }

    componentDidMount() {
        if (this.props.match.params.tabId) {
            const tab = this.props.match.params.tabId as TabName;
            store.dispatch(actionCreators.updateTab(tab))
        }
        this.currentConversion();
        this.getOptions();
    }

    getOptions = () => {
        this.options.get(this.conversionId)
            .then(values => {
                const options = values!.reduce(
                    (result, current) => ({ ...result, [current.id!]: current, }), {});
                this.setState({ options });
            })
            .catch(error => { });
    }

    toggle = (tab: TabName) => {
        if (this.props.currentTab !== tab) {
            store.dispatch(actionCreators.updateTab(tab))
            this.props.history.push({
                pathname: `/conversion/${this.conversionId}/${tab}`,
            })
        }
    }


    selected = (tab: TabName) => this.props.currentTab === tab;

    refreshPage = () => {
        this.setState(
            { reload: true },
            () => this.setState({ reload: false })
        )
    }

    currentConversion = () => {
        this.conversion.get(this.conversionId)
            .then(response =>
                this.setState({ currentConversion: response! }, () => {
                    this.checkConversion();
                })
            )
            .catch(error => { })
    }

    checkConversion = () => {
        const conversion = this.state.currentConversion;
        if (conversion) {
            const conversionType = conversion.conversionTypeId!.toUpperCase() === SourceSystem.ams360ToAMS360Id ? 'merge' : 'epic'
            this.conversion.getCheckInternalConnection(conversion!.staging || null, 'staging', conversionType)
                .then((result: boolean) => {
                    if (result) {
                        const stage = result
                        this.setState({ stage });
                    }
                    else if (!result && [Roles.customerAdministratorRoleId, Roles.customerUserRoleId].includes(this.props.currentUser!.role!)) {
                        console.log('Staging DB for Mini Conversion project does not exist.');
                    }
                })
                .catch((err: any) => {
                    console.log(err)
                })
        }
    }

    public render() {
        if (((this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase() === SourceSystem.miniToAMS360Id) ||
            ([Roles.customerAdministratorRoleId, Roles.customerUserRoleId].includes(this.props.currentUser!.role!))) && this.state.options && !this.customersFile) {
            for (const id of Object.keys(this.state.options)) {
                const value = this.state.options[id].value;
                if (id.toUpperCase() === MiniConstants.conversionOption_CustFileSelectedId && value === '1') {
                    this.customersFile = true;
                } else if (id.toUpperCase() === MiniConstants.conversionOption_PolFileSelectedId && value === '1') {
                    this.policiesFile = true;
                } else if ((id.toUpperCase() === MiniConstants.conversionOption_CustFileUploadedId && value === '1') || (id.toUpperCase() === MiniConstants.conversionOption_PolFileUploadedId && value === '1')) {
                    // Customers File or Policies File Uploaded for Mini Conversion
                    if (!this.props.currentTab) { this.toggle('edit'); }
                }
            }
        }

        return (
            <>
                <div className='container'>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.selected('edit') })}
                                onClick={() => this.toggle('edit')}>Edit Conversion</NavLink>
                        </NavItem>
                        <SecureContainer roles={[
                            Roles.administrationRoleId,
                            Roles.developerAdministrationRoleId,
                            Roles.vertaforeInternalRoleId,
                            Roles.noAccessRoleId
                        ]}>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.selected('options') })}
                                    onClick={() => this.toggle('options')}
                                    disabled={((this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase() === SourceSystem.miniToAMS360Id) ||
                                        ([Roles.customerAdministratorRoleId, Roles.customerUserRoleId].includes(this.props.currentUser!.role!)))}>Conversion Options
                                </NavLink>
                            </NavItem>
                        </SecureContainer>
                        {this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase() === SourceSystem.miniToAMS360Id && <NavItem>
                            <NavLink
                                className={classnames({ active: this.selected('minidash') })}
                                onClick={() => this.toggle('minidash')}>Dashboard</NavLink>
                        </NavItem>}
                        <SecureContainer roles={[
                            Roles.administrationRoleId,
                            Roles.developerAdministrationRoleId,
                            Roles.vertaforeInternalRoleId,
                            Roles.noAccessRoleId
                        ]}>
                            <>
                                {this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase() === SourceSystem.ams360ToAMS360Id && this.state.stage && <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.selected('mergetool') })}
                                        onClick={() => this.toggle('mergetool')}>Stage & Merge</NavLink>
                                </NavItem>}
                                {this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase() === SourceSystem.ams360ToAMS360Id && this.state.stage && <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.selected('mergestagetable') })}
                                        onClick={() => this.toggle('mergestagetable')}>Stage Review</NavLink>
                                </NavItem>}
                            </>
                        </SecureContainer>
                        {((this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase() === SourceSystem.epicToAMS360Id && this.state.stage) ||
                            (this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase() === SourceSystem.miniToAMS360Id)) && < NavItem >
                                <NavLink
                                    className={classnames({ active: this.selected('additionaldata') })}
                                    onClick={() => this.toggle('additionaldata')}>{this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase() === SourceSystem.miniToAMS360Id ? 'File Uploads' : 'Additional Data'}
                                </NavLink>
                            </NavItem>}
                        <NavItem>
                            <NavLink className={classnames({ active: this.selected('reports') })}
                                onClick={() => this.toggle('reports')}>
                                Reports
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.props.currentTab}>
                        <TabPane tabId='edit'>
                            <Route path='/conversion/:conversionId/:tabId' render={(props) => <ConversionEditPage {...props}
                                isEditing={true}
                                customersFile={this.customersFile}
                                policiesFile={this.policiesFile}
                            />}
                            />
                        </TabPane>
                        <SecureContainer roles={[
                            Roles.administrationRoleId,
                            Roles.developerAdministrationRoleId,
                            Roles.vertaforeInternalRoleId,
                            Roles.noAccessRoleId
                        ]}>
                            <TabPane tabId='options'>
                                <OptionsPage refreshPage={this.state.reload} />
                            </TabPane>
                        </SecureContainer>
                        <TabPane tabId='reports'>
                            <Route path='/conversion/:conversionId/:tabId'
                                render={(props) => <ConversionReportsPage {...props}
                                    conversionTypeId={(this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase()) || ''}
                                />}
                            />
                        </TabPane>
                        {((this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase() === SourceSystem.epicToAMS360Id) ||
                            ((this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase() === SourceSystem.miniToAMS360Id) ||
                                ([Roles.customerAdministratorRoleId, Roles.customerUserRoleId].includes(this.props.currentUser!.role!)))) &&
                            <TabPane tabId='additionaldata'>
                                <Route
                                    path='/conversion/:conversionId/:tabId'
                                    render={(props) => <AdditionalDataPage {...props}
                                        renderMode={((this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase() === SourceSystem.miniToAMS360Id) ||
                                            ([Roles.customerAdministratorRoleId, Roles.customerUserRoleId].includes(this.props.currentUser!.role!))) ? 'Mini' : 'Normal'}
                                        customersFile={this.customersFile}
                                        policiesFile={this.policiesFile}
                                    />}
                                />
                            </TabPane>}
                        {((this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase() === SourceSystem.miniToAMS360Id) ||
                            ([Roles.customerAdministratorRoleId, Roles.customerUserRoleId].includes(this.props.currentUser!.role!))) &&
                            <TabPane tabId='minidash'>
                                <Route
                                    path='/conversion/:conversionId/:tabId'
                                    render={(props) => <MiniDashPage {...props}
                                        currentUser={this.props.currentUser}
                                    />}
                                />
                            </TabPane>
                        }
                        <SecureContainer roles={[
                            Roles.administrationRoleId,
                            Roles.developerAdministrationRoleId,
                            Roles.vertaforeInternalRoleId,
                            Roles.noAccessRoleId
                        ]}>
                            <>
                                {this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase() === SourceSystem.ams360ToAMS360Id && this.state.stage &&
                                    <TabPane tabId='mergetool'>
                                        <MergeToolPage />
                                    </TabPane>}
                                {this.state.currentConversion && this.state.currentConversion!.conversionTypeId!.toUpperCase() === SourceSystem.ams360ToAMS360Id && this.state.stage &&
                                    <TabPane tabId='mergestagetable'>
                                        <MergeStageTablePage />
                                    </TabPane>}
                            </>
                        </SecureContainer>
                    </TabContent>
                </div>
            </>
        );
    }
}


export default withRouter(connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser,
            currentTab: state.select && state.select.tabName,
        }
    })(ConversionTabsPage));

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { CompanyClient, CompanyModel, CompanyRefModel, CompanyTypeRefModel, ConversionClient } from 'client/ApiClient';
import { enqueueNotification } from "../../../../../store/Notifications"
import { store } from "AppContext"
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, Tooltip, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button, DropdownItem } from 'reactstrap';

import { DataCell, DataRow, DataTable, DataTableButton } from 'components/basic/DataTable';
import { ValueChangeEvent } from 'components/basic/Events';
import DataListInput from 'components/basic/DataListInput';
import { RangePagination, RangePaginationState, paginate } from 'components/basic/Pagination';

import StageActionToolbar from '../generic/StageActionToolbar';
import { stagingPageUpdate, stagingPageCopyMappings, StagingPageCopyMappingsItem } from '../generic/Abstractions'
import { AssignRowStatus, RowStatus } from '../generic';

import 'styles/Icons.scss';

interface CompanyStagingPageArgs {
    conversionId: string,
}

type CompanyStagingPageProps = RouteComponentProps<CompanyStagingPageArgs>;

interface CompanyStagingPageState {
    companyRefs: CompanyRefModel[]
    companyTypeRefs: CompanyTypeRefModel[]
    items: (CompanyModel
        & StagingPageCopyMappingsItem)[]

    itemsByDestinationCode: (CompanyModel
        & StagingPageCopyMappingsItem)[]

    primaryList: {
        destinationCode?: string,
        parentDestinationCode?: string,
        pItems?: CompanyModel[]
    }[];

    sort: boolean;
    sortColumn: string;
    modal: boolean;
    modalType: string;
    canCancel: boolean;
    repeatedCode: string;
    lastValue: string;
    dropDownIndex: number | undefined;
    dropDownType: string | undefined;
    addToMainIndex: number | undefined;
    addToMainType: string | undefined;
    filter: string;
    filterBy: {
        query: string // A free text query
        mapped: boolean
        notMapped: boolean
        dropped: boolean
    }
    zero: boolean;
    tooltipOpen: boolean;
    tooltip: number | undefined;
    buttonLock: boolean;
    pagination: RangePaginationState;
    includeAddToMain: boolean;
}

export class CompanyStagingPage extends React.Component<CompanyStagingPageProps, CompanyStagingPageState>{
    private client: CompanyClient = new CompanyClient()
    private conversionClient: ConversionClient = new ConversionClient();

    private readonly copyMappings = stagingPageCopyMappings(this);

    constructor(props: CompanyStagingPageProps) {
        super(props)
        this.state = {
            companyRefs: [],
            companyTypeRefs: [],
            items: [],
            itemsByDestinationCode: [],
            primaryList: [],

            sort: true,
            sortColumn: '',
            modal: false,
            modalType: '',
            canCancel: false,
            repeatedCode: '',
            lastValue: '',
            dropDownIndex: undefined,
            dropDownType: undefined,
            addToMainIndex: undefined,
            addToMainType: undefined,
            filter: '',
            filterBy: {
                query: '',
                mapped: true,
                notMapped: true,
                dropped: true,
            },
            zero: false,
            tooltipOpen: false,
            tooltip: undefined,
            pagination: {
                pageSize: 25,
                pageNumber: 0,
            },
            includeAddToMain: false,
            buttonLock: false,
        }
    }

    private updateItemDetailsFromItems = (items: any) => {
        let validated = true;
        for (let i = 0; i < items.length; i++) {
            let { typeId, parentId } = this.getItemDetail(items[i]);
            items[i].parentTypeId = typeId
            items[i].parentId = parentId
            if (!items[i].isValid) validated = false;
        }
        const column = this.state.sortColumn;
        if (column !== '') {
            items.sort((a: any, b: any) => {
                const x = (column === 'parentNaic' || column === 'writingNaic') ? Number(a[column]) : a[column]
                const y = (column === 'parentNaic' || column === 'writingNaic') ? Number(b[column]) : b[column]
                if (x === y) {
                    return 0;
                }
                else if (x === null) {
                    return 1;
                }
                else if (y === null) {
                    return -1;
                }
                else if (!this.state.sort) {
                    return x < y ? -1 : 1;
                }
                else {
                    return x < y ? 1 : -1;
                }
            })
        }


        this.setState({
            items,
        })
    }

    private getItemDetail = (item: CompanyModel) => {
        let typeId: any = undefined;
        let parentId: any = undefined;
        let parentCode: any = undefined;
        if (!!item.referenceId) {
            const companyRefs = this.state.companyRefs
            let companyRef = [] as any;
            for (let i = 0; i < companyRefs.length; i++) {
                if (companyRefs[i].id === item.referenceId) {
                    companyRef = companyRefs[i]
                    break
                }
            }
            let parentCompanyRef = [] as any;
            for (let i = 0; i < companyRefs.length; i++) {
                if (companyRefs[i].code === companyRef!.parentCode) {
                    parentCompanyRef = companyRefs[i]
                    break
                }
            }
            typeId = parentCompanyRef && parentCompanyRef.typeId;
            parentId = parentCompanyRef && parentCompanyRef.id;
            parentCode = parentCompanyRef && parentCompanyRef.shortName;

            return {
                typeId, parentId, parentCode, companyRefName: `${companyRef.shortName} ${companyRef.name} ${companyRef.naic ? companyRef.naic : ''}`, parentCompanyName: `${parentCompanyRef.shortName} ${parentCompanyRef.name} ${parentCompanyRef.naic ? parentCompanyRef.naic : ''}`
            };
        } else {
            return { typeId, parentId, parentCode, name: null, parentCompanyName: null };
        }
    }

    getParentName = (parentId: number | undefined, index: number) => {
        const companyRefs = this.state.companyRefs
        let companyRef: CompanyRefModel;
        let items = this.state.items
        for (let i = 0; i < companyRefs.length; i++) {
            if (companyRefs[i].id === parentId) {
                companyRef = companyRefs[i]
                items[index].parentDestinationDescription = companyRef.name
                items[index].parentDestinationCode = companyRef.shortName
                break
            }
        }
        this.setState({ items })
    }

    componentDidMount() {
        this.getItems();
        this.UIController();
    }

    getItems = () => {
        const items = this.getCompanies();

        const companyRefs = this.client.getAllRefs(this.props.match.params.conversionId)

        const companyTypeRefs = this.client.getAllTypeRefs(this.props.match.params.conversionId)

        Promise.all([items, companyRefs, companyTypeRefs])
            .then(result => {
                const stateItems = result[0];
                this.updatePrimaryList(result[0]);
                this.setState({
                    companyRefs: result[1]!,
                    companyTypeRefs: result[2]!
                }, () => {
                    if (stateItems) {
                        this.updateItemDetailsFromItems(stateItems);
                    }
                })
            })
            .catch(error => { });
    }

    setDefaults = (entity: CompanyModel) => new CompanyModel({
        ...entity,
        parentNaic: entity.parentNaic || '',
        writingNaic: entity.writingNaic || '',
    })

    getCompanies = () => {
        return this.client.getAll(
            this.props.match.params.conversionId,
            '',
        )
    }

    refreshCompanies = () => {
        this.client.getAll(
            this.props.match.params.conversionId,
            '',
        )
            .then((result) => {
                if (result) {
                    this.setState({ items: result }, () => {
                        this.updatePrimaryList(this.state.items)
                        if (this.state.sortColumn !== '') {
                            this.setState({ sort: !this.state.sort }, () => {
                                this.handleSort(this.state.sortColumn)
                            })
                        }
                    })
                }
            })
    }


    put(entity: CompanyModel) {

        entity.parentNaic = !entity.parentNaic ? "" : entity.parentNaic
        entity.writingNaic = !entity.writingNaic ? "" : entity.writingNaic
        stagingPageUpdate(this, this.client)
            .withSource('Company', entity.writingSourceCode!)
            .update(entity)
            .then(() => {
                this.updatePrimaryList(this.state.items);
            })
            .catch(() => {
                this.clearMapError(entity)
            });
    }

    clearMapError(entity: CompanyModel) {
        const items = this.state.items.map((item, i) => {
            return item.id === entity.id
                ? new CompanyModel({
                    ...item,
                    referenceId: undefined,
                    parentReferenceId: undefined,
                    parentDestinationCode: undefined,
                    parentDestinationDescription: undefined,
                    destinationCode: undefined,
                    destinationDescription: undefined,
                    typeId: 0,
                    parentId: undefined,
                })
                : item;
        })
        const index = this.state.items.findIndex(i => i.id === entity.id)
        this.setState({
            items,
            addToMainIndex: undefined,
            addToMainType: undefined,
        })
    }

    putNoUpdateParents(entity: CompanyModel, parentId: number | undefined, index: number) {
        entity.parentNaic = !entity.parentNaic ? "" : entity.parentNaic
        entity.writingNaic = !entity.writingNaic ? "" : entity.writingNaic
        stagingPageUpdate(this, this.client)
            .withSource('Company', entity.writingSourceCode!)
            .update(entity)
            .then(() => {
                this.updatePrimaryList(this.state.items);
                this.getParentName(parentId, index);
            })
            .catch(() => { });
    }

    updatePrimaryList(items: CompanyModel[] | null) {
        let primaryList:
            {
                destinationCode?: string,
                parentDestinationCode?: string,
                pItems?: CompanyModel[]
            }[] = [];

        if (items) items.forEach((item: CompanyModel) => {
            if (item.parentDestinationCode && !item.parentReferenceId) {
                let primaryObject = primaryList.find(x => x.parentDestinationCode === item.parentDestinationCode);
                if (!primaryObject) {
                    primaryObject = { parentDestinationCode: item.parentDestinationCode, pItems: [] }
                    primaryList.push(primaryObject);
                }
                primaryObject.pItems!.push(item);
            }
            if (item.destinationCode && !item.referenceId) {
                let primaryObject = primaryList.find(x => x.destinationCode === item.destinationCode);
                if (!primaryObject) {
                    primaryObject = { destinationCode: item.destinationCode, pItems: [] }
                    primaryList.push(primaryObject);
                }
                primaryObject.pItems!.push(item);
            }
        });
        primaryList = primaryList.filter(x => x.pItems!.length > 1 && x.pItems!.some(p => p.isPrimary));
        this.setState({ primaryList })
    }

    private handleSort = (column: string) => {
        const items = this.state.items.sort((a: any, b: any) => {
            const x = (column === 'parentNaic' || column === 'writingNaic') ? Number(a[column]) : a[column]
            const y = (column === 'parentNaic' || column === 'writingNaic') ? Number(b[column]) : b[column]
            if (x === y) {
                return 0;
            }
            else if (x === null) {
                return 1;
            }
            else if (y === null) {
                return -1;
            }
            else if (this.state.sort) {
                return x < y ? -1 : 1;
            }
            else {
                return x < y ? 1 : -1;
            }
        })
        this.setState({ sort: !this.state.sort, sortColumn: column, items });
    }

    handleCheckboxChange = (item: CompanyModel, e: { target: { name: any, checked: any } }, index: number) => {
        let items = this.state.items.map(
            v => v.id === item.id
                ? new CompanyModel({
                    ...v,
                    [e.target.name]: e.target.checked,
                })
                : v)
        if (item.parentDestinationCode !== null && item.destinationCode === null && e.target.checked) {
            items = items.map(
                v => v.id === item.id
                    ? new CompanyModel({
                        ...v,
                        referenceId: undefined,
                        parentReferenceId: undefined,
                        parentId: undefined,
                        parentDestinationCode: undefined,
                        parentDestinationDescription: undefined,
                        destinationCode: undefined,
                        destinationDescription: undefined,
                        typeId: 0,
                        parentTypeId: 0,
                    })
                    : v)
        }
        this.setState({ items });
        this.put(items.find(v => v.id === item.id)!)
    }

    handleValueChange = (id: number, e: { target: { name: any }, value: any }) => {
        const items = this.state.items.map(
            v => v.id === id
                ? new CompanyModel({
                    ...v,
                    [e.target.name]: Number(e.value),
                })
                : v)
        this.setState({ items });
        this.put(items.find(v => v.id === id)!)
    }

    private handleCompanyTypeChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        const detail = this.state.items[index];

        const items: any[] = this.state.items.map((item, i) => {
            if (i === index) {
                const destinationCode = item.referenceId === null ? item.destinationCode : undefined;
                const parentDestinationCode = item.parentReferenceId === null ? item.parentDestinationCode : undefined;
                return {
                    ...item,
                    typeId: +e.target.value,
                    referenceId: undefined,
                    parentReferenceId: undefined,
                    destinationCode,
                    parentDestinationCode,
                    parentTypeId: +e.target.value,
                    parentId: undefined,
                    parentCode: undefined,
                }
            } else {
                return item;
            }
        });
        this.setState({ items });
        if (detail.parentTypeId !== +e.target.value && items[index].destinationCode !== null && items[index].referenceId === undefined) {
            this.put(items[index]);
        }
    }

    private handleDestinationParentChange = (event: ValueChangeEvent<HTMLInputElement, string>, index: number) => {
        let parent: CompanyRefModel | undefined = undefined;
        let items = this.state.items;
        if (this.state.items[index].parentId !== +event.value) {
            const parentId = +event.value;
            items = this.state.items.map((item, i) => {
                if (i === index) {
                    const companyRefs = this.state.companyRefs;

                    for (let i = 0; i < companyRefs.length; i++) {
                        if (companyRefs[i].id === parentId) {
                            parent = companyRefs[i]
                            break
                        }
                    }
                    return new CompanyModel({
                        ...item,
                        parentTypeId: (parent && parent.typeId) || item.parentTypeId,
                        parentId: parent && parent.id,
                    })
                } else {
                    return item;
                }
            })
            if (parent) {
                items = items.map((item, i) => {
                    return i === index
                        ? new CompanyModel({
                            ...item,
                            parentDestinationCode: parent!.shortName,
                            parentDestinationDescription: parent!.name,
                            typeId: (parent && parent.typeId) || this.state.items[index].parentTypeId!,
                        })
                        : item;
                })
            } else {
                this.clearMapping(index, true)
            }
            this.setState({ items });
        }
    }

    private handleDestinationWritingChange = (event: ValueChangeEvent<HTMLInputElement, string>, index: number) => {
        const value = event.value === '' ? undefined : +event.value;
        let items = this.state.items.map((item, i) => {
            return i === index
                ? new CompanyModel({
                    ...item,
                    referenceId: value,
                })
                : item;
        })

        if (value) {
            items = items.map((item, i) => {
                let { typeId, parentId, parentCode } = this.getItemDetail(items[i]);
                return i === index
                    ? new CompanyModel({
                        ...item,
                        parentTypeId: typeId,
                        parentId,
                        parentCode,
                    })
                    : item;
            })
            this.setState({ items }, () => {
                this.put(items[index]);
            });
        } else {
            this.clearMapping(index, true)
        }
    }

    handleCustomChange = (event: React.ChangeEvent<HTMLInputElement>, item: CompanyModel, index: number, type: string) => {
        const emptyString = event.target.value === '';
        let value = (event.target.value.trim().length <= 6 && !emptyString && item.parentSourceCode!.length > 0) ? event.target.value.trim() : undefined;
        let items = this.state.items;
        const code = type === 'parent' ? 'parentDestinationCode' : 'destinationCode'
        const name = type === 'parent' ? item.parentSourceDescription : item.writingSourceDescription
        const sourceCode = type === 'parent' ? item.parentSourceCode : item.writingSourceCode
        if (value) {
            this.client.companyUnique(this.props.match.params.conversionId, value, sourceCode!, name!, type)
                .then((response) => {
                    const uniqueCode = response ? response[0].uniqueCode : false;
                    const uniqueNAIC = response ? response[0].uniqueNAIC : false;
                    const uniqueName = response ? response[0].uniqueName : false;
                    if (uniqueCode && uniqueNAIC && uniqueName) {
                        if (type === 'parent') {
                            if (items[index].referenceId !== undefined && value === '') { items[index].destinationCode = undefined; }
                            items = items.map((item, i) => {
                                return i === index
                                    ? new CompanyModel({
                                        ...item,
                                        [code]: value,
                                        referenceId: undefined,
                                        parentReferenceId: undefined,
                                    })
                                    : item;
                            })
                        } else {
                            items = items.map((item, i) => {
                                return i === index
                                    ? new CompanyModel({
                                        ...item,
                                        [code]: value,
                                        referenceId: undefined,
                                    })
                                    : item;
                            })
                        }
                        if (type === 'writing') { items[index].parentReferenceId = items[index].parentId; }
                        if (type === 'parent') { items[index].parentId = undefined; }
                        this.setState({ items, dropDownIndex: undefined, dropDownType: undefined }, () => {
                            if (items[index].destinationCode !== null) this.put(items[index]);
                        });
                    } else {
                        const NAIC = type === 'parent' ? item.parentNaic : item.writingNaic
                        if (!uniqueCode) store.dispatch(enqueueNotification({ type: 'error', message: `${value} exists as Destination Code` }))
                        if (!uniqueNAIC) store.dispatch(enqueueNotification({ type: 'error', message: `NAIC code related to ${NAIC} exists` }))
                        if (!uniqueName) store.dispatch(enqueueNotification({ type: 'error', message: `${name} exists as Destination Name` }))
                        this.clearMapping(index, false)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            if (event.target.value.trim().length > 6) {
                store.dispatch(enqueueNotification({ type: 'error', message: `You are over the max 6 character length` }))
            }
            const code = type === 'parent' ? 'parentDestinationCode' : 'destinationCode'
            items = items.map((item, i) => {
                return i === index
                    ? new CompanyModel({
                        ...item,
                        referenceId: undefined,
                        [code]: undefined,
                    })
                    : item;
            })
            if (emptyString) {
                this.clearMapping(index, true)
            }
        }
        this.setState({ items, dropDownIndex: undefined, dropDownType: undefined })
        //if (type === 'parent') { itemDetails[index].parentId = undefined; }
        //this.setState({ items, dropDownIndex: undefined, dropDownType: undefined, itemDetails }, () => {
        //    if (type === 'parent') {
        //        this.put(items[index])
        //    }
        //    else {
        //        this.putNoUpdateParents(items[index], itemDetails[index].parentId, index)
        //    }
        //});
    }

    handleCustomInput = (event: React.ChangeEvent<HTMLInputElement>, index: number, type: string) => {
        const value = event.target.value.trim();
        const code = type === 'parent' ? 'parentDestinationCode' : 'destinationCode'
        let items = this.state.items;
        if (value === undefined && type === 'parent') {
            items = items.map((item, i) => {
                return i === index
                    ? new CompanyModel({
                        ...item,
                        parentDestinationCode: value,
                        destinationCode: value,
                    })
                    : item;
            })
        } else {
            items = items.map((item, i) => {
                return i === index
                    ? new CompanyModel({
                        ...item,
                        [code]: value,
                    })
                    : item;
            })
        }
        this.setState({ items, dropDownIndex: undefined, dropDownType: undefined });
    }

    private static isParentCompany = (c: CompanyRefModel) => c.code === c.parentCode;

    mappedCheck(check: 'notMapped' | 'mapped' | 'dropped') {
        this.setState(prev => {
            const filterBy = { ...prev.filterBy };

            switch (check) {
                case 'notMapped':
                    filterBy.notMapped = !filterBy.notMapped;
                    break;
                case 'mapped':
                    filterBy.mapped = !filterBy.mapped;
                    break;
                case 'dropped':
                    filterBy.dropped = !filterBy.dropped;
                    break;
            }

            return { filterBy };
        });
    }

    companyParentRefs = (index: number) => {
        const companyParentRefs = this.state.companyRefs;
        const typeId = this.state.items[index] && this.state.items[index].parentTypeId;
        const arr = []
        for (const [, companyref] of companyParentRefs.entries()) {
            const naic = companyref.naic ? `(${companyref.naic})` : ''
            if (CompanyStagingPage.isParentCompany(companyref) && (typeId === undefined || typeId === 0 || companyref.typeId === typeId)) {
                arr.push(<option
                    key={companyref.id}
                    data-value={companyref.id}
                >
                    {`${companyref.shortName} - ${companyref.name} ${naic}`}
                </option>)
            }
        }
        return arr;
    }

    companyRefs = (index: number) => {
        const companyRefs = this.state.companyRefs;
        const parentId = this.state.items[index] && this.state.items[index].parentId;
        let parent: CompanyRefModel | undefined = undefined
        for (let i = 0; i < companyRefs.length; i++) {
            if (companyRefs[i].id === parentId) {
                parent = companyRefs[i]
                break
            }
        }
        const arr = []
        for (const [, companyref] of companyRefs.entries()) {
            const naic = companyref.naic ? `(${companyref.naic})` : ''
            if (parent === undefined || companyref.parentCode === parent!.code) {
                arr.push(<option
                    key={companyref.id}
                    data-value={companyref.id}
                >
                    {`${companyref.shortName} - ${companyref.name} ${naic}`}
                </option>)
            }
        }
        return arr;
    }

    primarySelectionModalShowIfHasCode = (item: CompanyModel, type: string, canCancel: boolean) => {
        const repeatedCode = type === 'writing' ? item.destinationCode : item.parentDestinationCode;
        if (!repeatedCode) {
            alert('Please specify a destination code in order to set a primary record.');
        } else {
            this.primarySelectionModalShow(item, type, canCancel);
        }
    }

    primarySelectionModalShow = (item: CompanyModel, type: string, canCancel: boolean) => {
        const repeatedCode = type === 'writing' ? item.destinationCode! : item.parentDestinationCode!;
        this.setState({
            modal: true,
            modalType: type,
            canCancel: canCancel,
            repeatedCode,
        })
        this.getByDestinationCode(repeatedCode, type)
    }

    primarySelectionModalHide = () => {
        this.setState({ modal: false })
    }

    getByDestinationCode = (destinationCode: string, type: string) => {
        this.client.getAllByDestinationCode(this.props.match.params.conversionId, destinationCode, type)
            .then(response => {
                if (response != null) {
                    const itemsByDestinationCode = type === 'writing' ? response :
                        response.filter((item, index, self) => {
                            return index === self.findIndex((i) => (
                                i.parentSourceCode === item.parentSourceCode
                            ))
                        })
                    this.setState({ itemsByDestinationCode })
                }
            })
            .catch(error => { })
    }

    handleSelectDestination = (i: number, type: string) => {
        const itemsByDestinationCode = this.state.itemsByDestinationCode
        itemsByDestinationCode.map((item, index) => {
            if (type === 'writing') {
                if (index === i) {
                    item.isPrimary = true
                } else {
                    item.isPrimary = false
                }
            } else {
                if (index === i) {
                    item.parentIsPrimary = true
                } else {
                    item.parentIsPrimary = false
                }
            }
            return item;
        })
        this.setState({ itemsByDestinationCode })
    }

    putPrimary = (type: string) => {
        const codes = this.state.itemsByDestinationCode
        let num = 0
        if (type === 'writing') {
            codes.forEach(c => c.isPrimary ? num += 1 : null)
        } else {
            codes.forEach(c => c.parentIsPrimary ? num += 1 : null)
        }
        if (num === 1) {
            type === 'writing' ? codes.forEach(code => code.isPrimary ? this.updatePrimary(code.id) : null) : codes.forEach(code => code.parentIsPrimary ? this.updateParentPrimary(code.parentSourceCode) : null)
            this.modalToggle()
        } else {
            alert('Please only have one Source Code set to primary')
        }
    }

    updatePrimary(id: number) {
        this.client.updatePrimary(this.props.match.params.conversionId, this.state.repeatedCode, id, this.state.itemsByDestinationCode.length)
            .then(() => {
                this.refreshCompanies();
            }
            )
            .catch(error => { })
    }

    updateParentPrimary(sourceCode: string | undefined) {
        const code = sourceCode ? sourceCode : '';
        this.client.updateParentPrimary(this.props.match.params.conversionId, this.state.repeatedCode, code)
            .then(() => {
                this.refreshCompanies();
            })
            .catch(error => { })
    }

    modalToggle = () => {
        const modal = !this.state.modal
        this.setState({ modal })
    }

    showDropDown = (index: number | undefined, type: string | undefined) => {
        const dropDownIndex = (index === this.state.dropDownIndex && type === this.state.dropDownType) ? undefined : index;
        this.setState({ dropDownIndex, dropDownType: type, addToMainIndex: undefined, addToMainType: undefined })
    }

    addToMain = (index: number | undefined, type: string | undefined) => {
        const addToMainIndex = (index === this.state.addToMainIndex && type === this.state.addToMainType) ? undefined : index;
        const items = this.state.items.map((item, i) => {
            return i === index
                ? new CompanyModel({
                    ...item,
                    parentReferenceId: type === 'parent' ? undefined : item.parentReferenceId,
                    parentDestinationCode: type === 'parent' ? undefined : item.parentDestinationCode,
                    parentDestinationDescription: type === 'parent' ? undefined : item.parentDestinationDescription,
                    referenceId: type === 'writing' ? undefined : item.referenceId,
                    destinationCode: type === 'writing' ? undefined : item.destinationCode,
                    destinationDescription: type === 'writing' ? undefined : item.destinationDescription,
                })
                : item;
        })
        this.setState({ addToMainIndex, addToMainType: type, dropDownIndex: undefined, dropDownType: undefined, items })
    }

    clearMapping = (index: number, save: boolean) => {
        const items = this.state.items.map((item, i) => {
            return i === index
                ? new CompanyModel({
                    ...item,
                    referenceId: undefined,
                    parentReferenceId: undefined,
                    parentId: undefined,
                    parentDestinationCode: undefined,
                    parentDestinationDescription: undefined,
                    destinationCode: undefined,
                    destinationDescription: undefined,
                    isDrop: false,
                    isMapped: false,
                    typeId: 0,
                    parentTypeId: 0
                })
                : item;
        })
        this.setState({ items, addToMainIndex: undefined, addToMainType: undefined, dropDownIndex: undefined, dropDownType: undefined }, () => {
            if (save) {
                this.put(items[index])
            }
        });
    }

    handleSearch = (filter: string) => {
        this.setState({ filter })
    }

    zero = () => {
        this.setState({ zero: !this.state.zero })
    }

    UIController = () => {
        this.conversionClient.getUIController()
            .then((response) => {
                if (response) this.setState({ includeAddToMain: response!.addToMainEpic })
            })
            .catch(err => console.log(err))
    }

    lockButton = (lock: boolean) => {
        this.setState({ buttonLock: lock })
    }

    public render() {

        const conversionId = this.props.match.params.conversionId;
        const query = this.state.filter.trim().toLowerCase();
        const modalType = this.state.modalType;
        const zero = this.state.zero ? "Show All" : "Hide 0 Rows"
        const itemsByDestinationCode = this.state.itemsByDestinationCode;
        const items = this.state.items
            .map((x, index) => {
                const item = AssignRowStatus(x);
                return { item, index };
            })
            .filter(({ item }) =>
                (item.parentSourceCode || '').toLowerCase().includes(query)
                || (item.parentSourceDescription || '').toLowerCase().includes(query)
                || (item.writingSourceCode || '').toLowerCase().includes(query)
                || (item.writingSourceDescription || '').toLowerCase().includes(query)
                || (item.parentDestinationDescription || '').toLowerCase().includes(query)
                || (item.destinationDescription || '').toLowerCase().includes(query)
                || (item.destinationCode || '').toLowerCase().includes(query)
                || (item.parentDestinationCode || '').toLowerCase().includes(query)
                || (item.parentNaic || '').toLowerCase().includes(query)
                || (item.writingNaic || '').toLowerCase().includes(query))
            .filter(({ item }) =>
                (item.mapped === RowStatus.NotMapped && this.state.filterBy.notMapped)
                || (item.mapped === RowStatus.Mapped && this.state.filterBy.mapped)
                || (item.mapped === RowStatus.Dropped && this.state.filterBy.dropped))
            .filter(({ item }) => item.companyCount > 0 || !this.state.zero || item.writingSourceCode!.includes('Default'))
        return (
            <>
                <StageActionToolbar
                    conversionId={conversionId}
                    sectionId='company'
                    handleSearch={this.handleSearch}
                    handleCopyMapping={this.copyMappings.submitMappings}
                    primaryList={this.state.primaryList}
                    copyMap={true}
                    getItems={() => this.getItems()}
                    filter={this.state.filter}
                    lockButton={this.lockButton}
                />
                <DataTable>
                    <thead>
                        <tr className='nonFullWidthRow'>
                            <th className="parent-writing"></th>
                            <th className="companyTitle">
                                <UncontrolledDropdown setActiveFromChild className="ismappedDropdown">
                                    <DropdownToggle tag="a" className="ismapped" caret>
                                        Source Description
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem header>Sort:</DropdownItem>
                                        <DropdownItem onClick={() => this.handleSort('parentSourceDescription')}>Parent Name</DropdownItem>
                                        <DropdownItem onClick={() => this.handleSort('parentSourceCode')}>Parent Code</DropdownItem>
                                        <DropdownItem onClick={() => this.handleSort('parentNaic')}>Parent NAIC</DropdownItem>
                                        <DropdownItem onClick={() => this.handleSort('writingSourceDescription')}>Writing Name</DropdownItem>
                                        <DropdownItem onClick={() => this.handleSort('writingSourceCode')}>Writing Code</DropdownItem>
                                        <DropdownItem onClick={() => this.handleSort('writingNaic')}>Writing NAIC</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </th>
                            <th className='drop-column'>Type</th>
                            <th onClick={() => this.handleSort('parentDestinationDescription')} className='double'>
                                <UncontrolledDropdown setActiveFromChild className="ismappedDropdown">
                                    <DropdownToggle tag="a" className="ismapped" caret>
                                        Destination
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem header>Sort:</DropdownItem>
                                        <DropdownItem onClick={() => this.handleSort('parentDestinationCode')}>Parent Code</DropdownItem>
                                        <DropdownItem onClick={() => this.handleSort('parentDestinationDescription')}>Parent Name</DropdownItem>
                                        <DropdownItem onClick={() => this.handleSort('destinationCode')}>Writing Code</DropdownItem>
                                        <DropdownItem onClick={() => this.handleSort('destinationDescription')}>Writing Name</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </th>
                            <th className='status-column'></th>
                            {this.state.includeAddToMain && <th className='status-column'>Primary</th>}
                            <th onClick={() => this.handleSort('companyCount')} className='status-column'>Policies<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.handleSort('isDrop')} className='status-column'>Exclude<i className="fa fa-sort"></i></th>
                            <th className='status-column'>
                                <UncontrolledDropdown setActiveFromChild className="ismappedDropdown">
                                    <DropdownToggle tag="a" className="ismapped" caret>
                                        Mapped
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem className={!this.state.filterBy.notMapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('notMapped')}>&#x274C; Not Mapped</DropdownItem>
                                        <DropdownItem className={!this.state.filterBy.mapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('mapped')}>&#x2705; Mapped</DropdownItem>
                                        <DropdownItem className={!this.state.filterBy.dropped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('dropped')}>&#x2796; Excluded</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </th>
                            <th className='merge-name-toggle'><label className="switch"><input type="checkbox" readOnly checked={this.state.zero} onClick={this.zero} /> <span className="slider round"></span></label>{zero}</th>
                        </tr>
                    </thead >
                    <tbody>
                        {
                            paginate(items, this.state.pagination, ({ item, index }) => {
                                return <DataRow id={item.id.toString()} key={item.id} className='nonFullWidthRow'>
                                    <div className='nonFullWidthRow'>
                                        <DataCell className='parent-writing'>
                                            <div>Parent: </div>
                                        </DataCell>
                                        <DataCell className="companyTitle">
                                            <div>{item.parentSourceCode} {item.parentNaic ? '-' : null} {item.parentNaic}</div>
                                            <div>{item.parentSourceDescription}</div>
                                        </DataCell>
                                        <DataCell className='drop-column'>
                                            <select
                                                className="custom-select"
                                                name="companyType"
                                                value={(item && item.typeId) || ''}
                                                onChange={event => this.handleCompanyTypeChange(event, index)}
                                                disabled={this.state.buttonLock}
                                            >
                                                {this.state.companyTypeRefs.map((typeref) => (
                                                    <option
                                                        key={typeref.id}
                                                        value={typeref.id}
                                                    >
                                                        {typeref.description}
                                                    </option>
                                                ))}
                                            </select>
                                        </DataCell>
                                        <DataCell
                                            className='double addToMainContainer'
                                        >
                                            {index === this.state.dropDownIndex && this.state.dropDownType === 'parent' ?
                                                <div className={"addToMainEpicCompany"}>
                                                    <DataListInput
                                                        id={`companyparent${index}`}
                                                        name="companyparent"
                                                        value={(item && item.parentId) || ''}
                                                        onValueChange={event => this.handleDestinationParentChange(event, index)}
                                                        disabled={this.state.buttonLock}
                                                    >
                                                        <option data-value='' />
                                                        {this.companyParentRefs(index)}
                                                    </DataListInput>
                                                </div> : this.state.includeAddToMain && ((index === this.state.addToMainIndex && this.state.addToMainType === 'parent') || (item.parentDestinationCode && !item.parentDestinationDescription && !item.parentReferenceId)) ?
                                                    <Input
                                                        className={"addToMainEpicCompany"}
                                                        value={item.parentDestinationCode || ''}
                                                        onChange={(e) => this.handleCustomInput(e, index, 'parent')}
                                                        onFocus={(e) => (this.setState({ lastValue: e.target.value.trim(), addToMainIndex: index, addToMainType: 'parent' }))}
                                                        onBlur={(e) => {
                                                            if (item.parentDestinationCode !== this.state.lastValue) {
                                                                this.handleCustomChange(e, item, index, 'parent')
                                                            }
                                                        }}
                                                        disabled={item.typeId === 0 || this.state.buttonLock}
                                                        placeholder={"Add to Main - 6 max"}>{ }
                                                    </Input>
                                                    :
                                                    <Input
                                                        className={"addToMainEpicCompany"}
                                                        value={item.parentDestinationCode && item.parentDestinationDescription ? `${item.parentDestinationCode} - ${item.parentDestinationDescription}` : ''}
                                                        onFocus={() => this.showDropDown(index, "parent")}
                                                        disabled={this.state.buttonLock}
                                                    >{ }
                                                    </Input>
                                            }
                                            {item.parentSourceCode !== 'DefaultCompany' && item.parentSourceCode !== '' && this.state.includeAddToMain && < DataTableButton
                                                icon={((this.state.addToMainIndex === index && this.state.addToMainType === 'parent') || (item.parentDestinationCode && !item.parentDestinationDescription && !item.parentReferenceId)) && index !== this.state.dropDownIndex ? 'list' : 'edit'}
                                                id={"addToMain"}
                                                title={((this.state.addToMainIndex === index && this.state.addToMainType === 'parent') || (item.parentDestinationCode && !item.parentDestinationDescription && !item.parentReferenceId)) && index !== this.state.dropDownIndex ? 'Dropdown List' : 'Add To Main'}
                                                action={() => (item.parentDestinationCode && !item.parentDestinationDescription && !item.parentReferenceId) && index !== this.state.dropDownIndex ? this.showDropDown(index, "parent") : this.addToMain(index, "parent")}
                                                disabled={this.state.buttonLock}
                                            />}
                                        </DataCell>
                                        <DataCell className='status-column'>
                                            {item.parentDestinationCode &&
                                                <DataTableButton
                                                    icon='backspace'
                                                    id={item.id + '-clear'}
                                                    title={'Clear ' + item.parentDestinationCode}
                                                    disabled={this.state.buttonLock}
                                                    action={() => this.clearMapping(index, true)} />}
                                        </DataCell>
                                        {this.state.includeAddToMain && <DataCell className='status-column' itemProp={this.state.primaryList.some(p => p.parentDestinationCode === item.parentDestinationCode! && p.parentDestinationCode) && item.isMapped ? item.parentIsPrimary ?
                                                'primary'
                                                : 'non-primary' : ''}>
                                            <DataTableButton
                                                id={item.id + '-parentPrimary'}
                                                action={() => this.primarySelectionModalShowIfHasCode(item, 'parent', true)}
                                                disabled={this.state.buttonLock}
                                                title='Click to set a parent primary record'
                                            >
                                                {this.state.primaryList.some(p => p.parentDestinationCode === item.parentDestinationCode! && p.parentDestinationCode) && item.isMapped ? item.parentIsPrimary ? <span className='mapped' itemProp='primary'>&#x2705;</span> : <span className='mapped' itemProp='non-primary'>&#x274C;</span> : null}
                                            </DataTableButton>
                                        </DataCell>}
                                        <DataCell className='status-column'>{!item.writingSourceCode!.includes('Default') && item.companyCount}</DataCell>
                                        <DataCell className='status-column'>
                                            <input className='custom-checkbox'
                                                type='checkbox'
                                                name='isDrop'
                                                checked={item.isDrop}
                                                onChange={event => this.handleCheckboxChange(item, event, index)}
                                                disabled={this.state.buttonLock}
                                            />
                                        </DataCell>
                                        <DataCell className='status-column'>
                                            {item.mapped === RowStatus.NotMapped ? < p className='mapped' itemProp='unmapped'>&#x274C;</p> : item.mapped === RowStatus.Mapped ? < p className='mapped' itemProp='mapped'>&#x2705;</p> : < p className='mapped' itemProp='dropped'>&#x2796;</p>}
                                        </DataCell>
                                        <DataCell className='merge-name-toggle'>
                                        </DataCell>
                                    </div>
                                    <div className='nonFullWidthRow'>
                                        <DataCell className='parent-writing'>
                                            <div>Writing: </div>
                                        </DataCell>
                                        <DataCell className="companyTitle">
                                            <div>{item.writingSourceCode} {item.writingNaic ? '-' : null} {item.writingNaic}</div>
                                            <div>{item.writingSourceDescription}</div>
                                        </DataCell>
                                        <DataCell className='drop-column'>
                                        </DataCell>
                                        <DataCell
                                            className='double addToMainContainer writing'
                                        >
                                            {index === this.state.dropDownIndex && this.state.dropDownType === 'writing' ?
                                                <div className={"addToMainEpicCompany"}>
                                                    <DataListInput
                                                        id={`companywriting${index}`}
                                                        name="companywriting"
                                                        value={item.referenceId || ''}
                                                        onValueChange={event => this.handleDestinationWritingChange(event, index)}
                                                        disabled={item.parentDestinationCode === undefined || (item.parentDestinationCode === null && (item.parentId === undefined || item.parentId === null)) || this.state.buttonLock}
                                                    >
                                                        <option data-value='' />
                                                        {this.companyRefs(index)}
                                                    </DataListInput>
                                                </div> : this.state.includeAddToMain && (index === this.state.addToMainIndex || (item.destinationCode && !item.destinationDescription && !item.referenceId) || (item.parentDestinationCode && !item.parentDestinationDescription && !item.parentReferenceId)) ?
                                                    <Input
                                                        className={"addToMainEpicCompany"}
                                                        value={item.destinationCode ? item.destinationCode : ''}
                                                        onChange={(e) => this.handleCustomInput(e, index, 'writing')}
                                                        onFocus={(e) => (this.setState({ lastValue: e.target.value.trim(), addToMainIndex: index, addToMainType: 'writing' }))}
                                                        onBlur={(e) => {
                                                            if (item.destinationCode !== this.state.lastValue) {
                                                                this.handleCustomChange(e, item, index, 'writing')
                                                            }
                                                        }
                                                        }
                                                        disabled={item.parentDestinationCode === undefined || (item.parentDestinationCode === null && (item.parentId === undefined || item.parentId === null)) || this.state.buttonLock}
                                                        placeholder={"Add to Main - 6 max"}>{ }
                                                    </Input>
                                                    :
                                                    <Input
                                                        className={"addToMainEpicCompany"}
                                                        value={item.destinationCode && item.destinationDescription ? `${item.destinationCode} - ${item.destinationDescription}` : ''}
                                                        onFocus={() => this.showDropDown(index, "writing")}
                                                        disabled={item.parentDestinationCode === undefined || (item.parentDestinationCode === null && (item.parentId === undefined || item.parentId === null)) || this.state.buttonLock}
                                                    >{ }
                                                    </Input>
                                            }
                                            {item.parentSourceCode !== 'DefaultCompany' && item.parentSourceCode !== '' && (item.parentDestinationCode !== null || item.parentId !== undefined) && this.state.includeAddToMain && < DataTableButton
                                                icon={index === this.state.addToMainIndex || (item.destinationCode && !item.destinationDescription && !item.referenceId && index !== this.state.dropDownIndex) || (item.parentDestinationCode && !item.parentDestinationDescription && !item.parentReferenceId) ? 'list' : 'edit'}
                                                id={"addToMain"}
                                                title={index === this.state.addToMainIndex || (item.destinationCode && !item.destinationDescription && !item.referenceId && index !== this.state.dropDownIndex) || (item.parentDestinationCode && !item.parentDestinationDescription && !item.parentReferenceId) ? 'Dropdown List' : 'Add To Main'}
                                                action={() => index === this.state.addToMainIndex || (item.destinationCode && !item.destinationDescription && !item.referenceId && index !== this.state.dropDownIndex) || (item.parentDestinationCode && !item.parentDestinationDescription && !item.parentReferenceId) ? this.showDropDown(index, "writing") : this.addToMain(index, "writing")}
                                            />}
                                        </DataCell>
                                        <DataCell className='status-column'>
                                        </DataCell>
                                        {this.state.includeAddToMain &&
                                            <DataCell className='status-column' itemProp={this.state.primaryList.some(p => p.destinationCode === item.destinationCode && p.destinationCode) && item.isMapped ? !item.isPrimary && item.isMapped ?
                                                    'non-primary'
                                                    : 'primary' : ''}>
                                                {item.parentIsPrimary &&
                                                    <DataTableButton
                                                        id={item.id + '-primary'}
                                                        action={() => this.primarySelectionModalShowIfHasCode(item, 'writing', true)}
                                                        title='Click to set a writing primary record'
                                                        disabled={this.state.buttonLock}
                                                    >
                                                        {this.state.primaryList.some(p => p.destinationCode === item.destinationCode && p.destinationCode) && item.isMapped ? !item.isPrimary && item.isMapped ? <span className='mapped' itemProp='non-primary'>&#x274C;</span> : <span className='mapped' itemProp='primary'>&#x2705;</span> : null}
                                                    </DataTableButton>}
                                        </DataCell>}
                                        <DataCell className='status-column'></DataCell>
                                        <DataCell className='status-column'></DataCell>
                                        <DataCell className='status-column'></DataCell>
                                        <DataCell className='merge-name-toggle'>
                                        </DataCell>
                                    </div>
                                </DataRow>
                            })
                        }
                    </tbody>
                </DataTable >
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={items.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
                <div>
                    <Modal isOpen={this.state.modal}>
                        <ModalHeader>Please Set {modalType.charAt(0).toUpperCase()}{modalType.slice(1)} Primary for: {this.state.repeatedCode} </ModalHeader>
                        <ModalBody>
                            <select
                                name='isPrimary'
                                className="custom-select"
                                onChange={(e) => this.handleSelectDestination(Number(e.target.value), modalType)}
                                id={this.state.repeatedCode.toString()}
                                key={this.state.repeatedCode}
                            >
                                {itemsByDestinationCode
                                    .filter(i => {
                                        if (modalType === 'writing') {
                                            return i.parentIsPrimary
                                        } else {
                                            return i
                                        }
                                    })
                                    .map((item, index) => (
                                    <option
                                        value={index}
                                        key={item.id}
                                        selected={modalType === 'writing' ? item.isPrimary : item.parentIsPrimary}
                                    >
                                        {modalType === 'writing' ? `${item.writingSourceCode} - ${item.writingSourceDescription}`
                                            : item.parentSourceCode !== '' ?
                                                `${item.parentSourceCode} - ${item.parentSourceDescription}` : 'Empty Source Description'}
                                    </option>
                                ))}
                            </select>
                        </ModalBody>
                        <ModalFooter>
                            {this.state.canCancel && <Button color="secondary" onClick={() => this.primarySelectionModalHide()}>Close</Button>}
                            <Button color="primary" onClick={() => this.putPrimary(modalType)}>Submit</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );
    }
}
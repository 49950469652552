import * as React from 'react';
import { Prompt } from 'react-router';
import _ from 'lodash';

export function promptAgainstState<P, S extends T & { initial: T }, T = S>(
    component: React.ComponentClass<P, S>, selector: (state: T) => any, message?: string): React.ComponentClass<P, S> {

    return class PromptedComponent extends component {
        private static readonly Message = "Changes you made will be lost. Do you really want to leave the page?";

        private stateWasChanged = (): boolean => {
            const initial = selector(this.state.initial);
            const current = selector(this.state);

            return !_.isEqual(initial, current);
        }

        private handleBeforeUnload = ((e: BeforeUnloadEvent) => {
            if (!this.stateWasChanged()) {
                return null;
            }

            e.preventDefault();
            e.returnValue = true;

            return true;
        });

        componentDidMount() {
            window.addEventListener("beforeunload", this.handleBeforeUnload);
            super.componentDidMount && super.componentDidMount();
        }

        componentWillUnmount() {
            super.componentWillUnmount && super.componentWillUnmount();
            window.removeEventListener("beforeunload", this.handleBeforeUnload);
        }

        render() {
            return (
                <React.Fragment>
                    <Prompt
                        message={message || PromptedComponent.Message}
                        when={this.stateWasChanged()}
                    />
                    {super.render()}
                </React.Fragment>
            )
        }
    }
}
